
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Watch } from "vue-property-decorator";
    import ApiButton from "@/components/ApiButton.vue";
    import { BidfoodDataRequest } from "@/model/api/BidfoodDataRequest";
    import { CategoryTotalsRequest } from "@/model/api/CategoryTotalsRequest";
    import { UserAgreementCustomer } from "@/model/User";
    import ApiClient from "@/utilities/ApiClient";
    import FileDownload from "@/utilities/FileDownload";
    import Utils from "@/utilities/Utils";

    @Component({
        components: { ApiButton }
    })
    export default class Downloads extends Vue {

        //
        // -- properties
        //

        private customers: Array<UserAgreementCustomer> = [];

        bidfoodDataRequest: BidfoodDataRequest = new BidfoodDataRequest()
        bidfoodDataConfig = {
            format: "MMMM yyyy",
            maxDate: new Date()
        }

        categoryTotalsRequest: CategoryTotalsRequest = new CategoryTotalsRequest()

        //
        // -- watchers 
        //

        @Watch("$store.state.layoutInitDone", { immediate: true, deep: true })
        private async onLayoutInit(initComplete: boolean) {
            if (!initComplete) return;

            Utils.resetObject(this.bidfoodDataRequest);
            Utils.resetObject(this.categoryTotalsRequest);
            this.bidfoodDataRequest.date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
            this.categoryTotalsRequest.year = new Date().getFullYear();

            await this.getCustomers();
        }

        @Watch("$store.state.includeInactive", { immediate: true, deep: true })
        private onIncludeInactiveChanged(value: boolean) {
            if (!value && !Utils.isEmptyId(this.bidfoodDataRequest.dropAccount)) {
                const item = this.customers.filter(c => c.dropAccount == this.bidfoodDataRequest.dropAccount)[0];
                if (!item.isActive) this.bidfoodDataRequest.dropAccount = 0;
            }

            if (!value && !Utils.isEmptyId(this.categoryTotalsRequest.dropAccount)) {
                const item = this.customers.filter(c => c.dropAccount == this.categoryTotalsRequest.dropAccount)[0];
                if (!item.isActive) this.categoryTotalsRequest.dropAccount = 0;
            }
        }

        //
        // -- computed properties
        //

        private get customer(): string {
            return this.$store.state.canChangeCustomer ? this.$store.state.agreementName : this.$store.state.customerName;
        }

        private get catTotalYearOptions(): Array<number> {
            const retVal: Array<number> = [];
            const date = new Date();

            while (retVal.length < 10) {
                retVal.push(date.getFullYear());
                date.setFullYear(date.getFullYear() - 1);
            }

            return retVal
        }

        private get customerOptions(): Array<UserAgreementCustomer> {
            const retVal: Array<UserAgreementCustomer> = [];
            retVal.push(new UserAgreementCustomer({ dropAccount: 0, customerName: "All Customers", isActive: true }));
            this.customers.forEach(c => {
                if (this.$store.state.includeInactive || c.isActive) {
                    retVal.push(c);
                }
            });
            return retVal;
        }

        //
        // -- methods
        //

        private getCustomers = async () => {
            this.customers.length = 0;
            const agreementNumber = this.$store.state.agreementNumber;      
            const serverData = await ApiClient.get(`api/agreement/customers?agreementNumber=${agreementNumber}`);
            this.customers.push(...serverData.map((c: any) => new UserAgreementCustomer(c)));
        }

        private downloadBidfoodData() {
            this.bidfoodDataRequest.filename = "Bidfood Data.pdf";
            this.bidfoodDataRequest.agreementNumber = this.$store.state.agreementNumber;
            this.bidfoodDataRequest.includeInactive = this.$store.state.includeInactive;
            FileDownload.download("api/report/bidfoodData", this.bidfoodDataRequest);
        }

        private downloadCategoryTotals() {
            this.categoryTotalsRequest.filename = "Monthly Category Totals.xls";
            this.categoryTotalsRequest.agreementNumber = this.$store.state.agreementNumber;
            this.categoryTotalsRequest.includeInactive = this.$store.state.includeInactive;
            FileDownload.download("api/report/categoryTotals", this.categoryTotalsRequest);
        }

    }
